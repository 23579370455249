import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import Blockquote from 'components/Blockquote';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "logos",
      "style": {
        "position": "relative"
      }
    }}>{`Logos`}</h2>
    <Blockquote title="Well refactor the logos" mdxType="Blockquote">
      <p>{`  We will refactor the logos in the future. This file gives a listing of how the logos are structured. If you still have questions, please contact us.`}</p>
    </Blockquote>
    <h3 {...{
      "id": "dl",
      "style": {
        "position": "relative"
      }
    }}>{`DL`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-compleet == abo-compleet
abo-digitaal-extra == abo-digitaal-extra
abo-digitaal == abo-digitaal
abo-zaterdag-extra == abo-zaterdag-extra
brand-dark -> brand-square-main
brand-horizontal-dark -> brand-main
brand-horizontal-light -> brand-main
brand-lettermark-dark -> removed -> brand-square-main
brand-lettermark-light -> removed -> brand-square-main
brand-light -> brand-square-main
brand-plus -> brand-plus-main
brand-wordmark-dark -> brand-name
brand-wordmark-light -> brand-name-white
brand-icon -> removed -> brand-square-main
`}</code></pre>
    <h3 {...{
      "id": "ds",
      "style": {
        "position": "relative"
      }
    }}>{`DS`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal == abo-digitaal
abo-slim == abo-slim
abo-snel == abo-snel
abo-super == abo-super
brand-dark -> brand-main (cdn only)
brand-horizontal-dark -> brand-main (cdn only)
brand-horizontal-light -> removed -> brand-main (cdn only)
brand-lettermark-dark -> brand-square-main (cdn only)
brand-lettermark-light -> brand-square-icon-white (cdn only)
brand-light -> removed -> brand-main (cdn only)
brand-plus -> brand-plus-main (cdn only)
brand-wordmark-dark -> brand-main (cdn only)
brand-wordmark-light -> removed
podcast -> audio (cdn only)
`}</code></pre>
    <h3 {...{
      "id": "gva---gva-summer-2020",
      "style": {
        "position": "relative"
      }
    }}>{`GVA -> GVA-SUMMER-2020`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal == abo-digitaal
abo-slim == abo-slim
abo-snel == abo-snel
abo-super == abo-super
brand-dark -> removed
brand-horizontal-dark -> brand-main (cdn only)
brand-horizontal-light -> brand-square-icon-name-horizontal-white (cdn only)
brand-lettermark-dark -> brand-icon (cdn only)
brand-lettermark-light -> brand-icon-white (cdn only)
brand-light -> brand-square-icon-name-vertical (cdn only)
brand-plus -> brand-plus-main (cdn only)
brand-square-dark -> brand-square-main/brand-square-icon (cdn only)
brand-square-light -> brand-square-icon-white
brand-wordmark-dark -> removed
brand-wordmark-light -> removed
`}</code></pre>
    <h3 {...{
      "id": "gva-summer-2020",
      "style": {
        "position": "relative"
      }
    }}>{`GVA-SUMMER-2020`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal == abo-digitaal
abo-slim == abo-slim
abo-snel == abo-snel
abo-super == abo-super
brand-icon-white == brand-icon-white
brand-icon == brand-icon
brand-main == brand-main
brand-name-white == brand-name-white (not on cdn)
brand-name == brand-name (not on cdn)
brand-plus-main ==  brand-plus-main
brand-plus -> brand-plus-main
brand-square-icon-name-horizontal-white == brand-square-icon-name-horizontal-white
brand-square-icon-name-horizontal == brand-square-icon-name-horizontal
brand-square-icon-name-vertical == brand-square-icon-name-vertical
brand-square-icon-white == brand-square-icon-white
brand-square-icon == brand-square-icon
brand-square-main == brand-square-main
`}</code></pre>
    <h3 {...{
      "id": "hbvl---hbvl-summer-2020",
      "style": {
        "position": "relative"
      }
    }}>{`HBVL -> HBVL-SUMMER-2020`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal == abo-digitaal
abo-slim == abo-slim
abo-snel == abo-snel
abo-super == abo-super
abo-weekend == abo-weekend

brand-dark -> brand-square-main (cdn only)
brand-horizontal-dark -> brand-main (cdn only)
brand-horizontal-light -> brand-main (cdn only)
brand-lettermark-dark -> brand-icon (cdn only)
brand-lettermark-light -> brand-icon (cdn only)
brand-light -> brand-square-main (cdn only)
brand-plus -> brand-plus-main (cdn only)
brand-wordmark-dark -> brand-name (cdn only)
brand-wordmark-light -> brand-name (cdn only)
`}</code></pre>
    <h3 {...{
      "id": "hbvl-summer-2020",
      "style": {
        "position": "relative"
      }
    }}>{`HBVL-SUMMER-2020`}</h3>
    <p>{`Nothing changed, but brand-plus should be removed`}</p>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal == abo-digitaal
abo-slim == abo-slim
abo-snel == abo-snel
abo-super == abo-super
abo-weekend == abo-weekend

brand-main == brand-main
brand-square-main == brand-square-main
brand-plus-main == brand-plus-main

brand-square-icon == brand-square-icon
brand-square-icon-name-horizontal == brand-square-icon-name-horizontal
brand-name == brand-name
brand-name-white == brand-name-white
brand-icon == brand-icon
brand-plus == brand-plus (SHOULD BE REMOVED IN FAVOUR OF brand-plus-main)



`}</code></pre>
    <h3 {...{
      "id": "hmc",
      "style": {
        "position": "relative"
      }
    }}>{`HMC`}</h3>
    <p>{`No changes`}</p>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "hmc-ge",
      "style": {
        "position": "relative"
      }
    }}>{`HMC-GE`}</h3>
    <p>{`No changes`}</p>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "hmc-hd",
      "style": {
        "position": "relative"
      }
    }}>{`HMC-HD`}</h3>
    <p>{`No changes`}</p>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "hmc-ld",
      "style": {
        "position": "relative"
      }
    }}>{`HMC-LD`}</h3>
    <p>{`No changes`}</p>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "hmc-nhd",
      "style": {
        "position": "relative"
      }
    }}>{`HMC-NHD`}</h3>
    <p>{`No changes`}</p>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "hmc-yc",
      "style": {
        "position": "relative"
      }
    }}>{`HMC-YC`}</h3>
    <p>{`No changes`}</p>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "hub",
      "style": {
        "position": "relative"
      }
    }}>{`HUB`}</h3>
    <pre><code parentName="pre" {...{}}>{`brand-main
brand-square-main
brand-plus-main
`}</code></pre>
    <h3 {...{
      "id": "nb",
      "style": {
        "position": "relative"
      }
    }}>{`NB`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal == abo-digitaal
abo-slim == abo-slim
abo-snel == abo-snel
abo-snel == abo-super
brand-dark -> brand-icon-name-vertical (only on cdn)
brand-horizontal-dark -> removed
brand-horizontal-light -> brand-main (only on cdn)
brand-lettermark-dark -> brand-icon (only on cdn)
brand-lettermark-light -> brand-icon-white (only on cdn)
brand-light -> brand-square-main (only on cdn)
brand-plus -> brand-plus-main (only on cdn)
brand-wordmark-dark -> brand-name (only on cdn)
brand-wordmark-light -> brand-name-white (only on cdn)
`}</code></pre>
    <h3 {...{
      "id": "wl",
      "style": {
        "position": "relative"
      }
    }}>{`WL`}</h3>
    <pre><code parentName="pre" {...{}}>{`abo-digitaal -> abo-digitaal (only in package)
abo-slim -> abo-slim (only in package)
abo-snel -> abo-snel (only in package)

brand-main (only on cdn)
brand-square-main (only on cdn)
brand-plus-main (only on cdn)
brand-square-icon (only on cdn)
brand-square-icon-name-horizontal (only on cdn)
brand-square-icon-name-horizontal-white (only on cdn)
brand-icon-name-vertical (only on cdn)
brand-icon-name-vertical-white (only on cdn)
brand-icon (only on cdn)
brand-icon-white (only on cdn)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      